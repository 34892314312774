<template>
	<!-- 咨询部人员创建的个人任务 -->
	<div class="bg-white">
		<el-tabs type="border-card">
			<el-tab-pane style="min-height: 70vh;">
				<span slot="label">
					<i class="el-icon-finished"></i>
					我的任务
				</span>
				<div class="flex justify-content-between">
					<button-list :btnList="taskList" @btnClick="taskLists" />
					<el-row>
						<el-button type="primary" icon="el-icon-plus" @click="taskSubmission" size="small">新建任务
						</el-button>
					</el-row>
				</div>
				<template>
					<div class="flex flex-wrap-wrap" v-if="GetTaskList.length" v-loading='loading'>
						<div class="padding border-grey border-radius  task-div"
							style="width: 24%;margin: 10px 10px 0 0;box-shadow: 0 0 5px #cdcdcd;"
							v-for="(item,index) of GetTaskList" :key="index" @click="taskdetail(item.id,index)">
							<!-- 	<div class="flex aligin-items-center justify-content-between"> -->
							<div style="position: absolute;top:20px;right: 10px;">
								<span>
									<svg class="icon BgIcon" aria-hidden="true" v-if="item.ContactType==1">
										<use xlink:href="#icon-dianhua1"></use>
									</svg>
									<svg class="icon BgIcon" aria-hidden="true" v-if="item.ContactType==2">
										<use xlink:href="#icon-duanxin"></use>
									</svg>
									<svg class="icon BgIcon" aria-hidden="true" v-if="item.ContactType==3">
										<use xlink:href="#icon-weixin" style="font-size: 100px !important;"></use>
									</svg>
									<svg class="icon BgIcon" aria-hidden="true" v-if="item.ContactType==4">
										<use xlink:href="#icon-qq"></use>
									</svg>
									<svg class="icon BgIcon" aria-hidden="true" v-if="item.ContactType==5">
										<use xlink:href="#icon-youjian"></use>
									</svg>
									<svg class="icon BgIcon" aria-hidden="true" v-if="item.ContactType==6">
										<use xlink:href="#icon-weizhi"></use>
									</svg>
									<svg class="icon BgIcon" aria-hidden="true" v-if="item.ContactType==7">
										<use xlink:href="#icon-tixing"></use>
									</svg>
								</span>
							</div>
							<div style="height: 25px;width: 100%;">
								<span class="text-bold text-lg text-black"
									style="font-size: 18px;line-height: 25px">{{item.TaskTitle}}</span>
							</div>
							<div style="height:30px;line-height: 30px;font-size: 14px;">{{item.PhoneNumber1}}</div>
							<div class="padding-top-sm">
								<span>
									<i class="el-icon-user el-icon--left" />
									<span style="width: 70px;display: inline-block;">客户</span>
								</span>
								<span>
									<label v-if="item.TaskStatus!=2" class="cursor">{{item.CustomerUserName}}</label>
									<span v-else>{{item.CustomerUserName}}</span>
								</span>
							</div>
							<div class="padding-top-xs">
								<span>
									<i class="el-icon-time el-icon--left" />
									<span style="width: 70px;display: inline-block;">执行时间</span>
								</span>
								<span>
									<span>{{item.CompleteTime | dateTimeFilter}}</span>
								</span>
								<div style="float: right;font-weight: bold;">
									<span v-if="item.TaskStatus==1" class="text-orange">未执行</span>
									<span v-if="item.TaskStatus==2" style="color: #0077AA;">执行成功</span>
									<span v-if="item.TaskStatus==3" class="text-red">执行失败</span>
								</div>
							</div>
						</div>

					</div>
					<div v-else>
						<div style="text-align: center;">
							<div class="text-black" style="margin-top: 100px;">
								<i class="iconfont icon-zanwushuju" style="font-size: 64px;"></i>
								<div>暂无数据</div>
							</div>

						</div>
					</div>
				</template>
			</el-tab-pane>
		</el-tabs>
		<el-pagination background layout="total,prev, pager, next,sizes,jumper" highlight-current-row :total="total"
			:page-size="ListQuery.PageSize" :page-sizes="[15,20,50,100,200,500]" @current-change="pageIndexChange"
			@size-change="pageSizeChange" style="text-align: center;" />
	</div>
</template>

<script>
	import ButtonList from '../salesManagement/components/buttonList.vue';
	import {
		formatTime
	} from '@/utils/util.js'
	export default {
		components: {
			ButtonList,
		},
		data() {
			return {
				loading: true,
				editVisible: false,
				total: 0,
				//销售计划的tab  即计划状态
				taskList: [{
						name: '今日截止',
						value: 0
					},
					{
						name: '待执行',
						value: 1
					},
					{
						name: '已结束',
						value: 2
					},
				],
				planIndex: 0,
				GetTaskList: [], //任务的数据
				ListQuery: {
					All: false,
					PageIndex: 1,
					type:1,
					PageSize: 15,
					Sort: [{
						"Field": "id",
						"Type": 1
					}]
				}

			};
		},
		created() {
			this.GetTaskLists()
		},
		mounted() {},
		methods: {
			taskLists(index) {
				this.GetTaskList=[];
				this.planIndex = index;
				this.ListQuery.PageIndex=1;
				this.GetTaskLists()
			},
			taskSubmission() {
				this.$Dialog.TaskSubmission()
			},
			pageSizeChange(val) {
				console.log('val',val)
				this.ListQuery.PageIndex = 1;
				this.ListQuery.pageSize = val;
				this.GetTaskLists();
			},
			pageIndexChange(val) {
				console.log(this.planIndex)
				this.ListQuery.PageIndex = val;
				this.GetTaskLists();
			},
			GetTaskLists() {
				// let methods = ['CustomerTaskGetTodayTaskList', 'CustomerTaskGetTaskListByStatus',
				// 	'CustomerTaskGetTaskListByStatus', 'CustomerTaskGetTaskListByStatus'
				// ];
				
				let methods = ['CustomerTaskGetTodayTaskList', 'CustomerTaskGetTaskListByStatus',
					 'CustomerTaskGetTaskListByStatus'
				];
				let method = methods[this.planIndex];
				if (this.planIndex == 1 || this.planIndex == 2) {
					this.ListQuery.All = false
					this.ListQuery.TaskStatus = this.planIndex
				} 
				// else if (this.planIndex == 3) {
				// 	this.ListQuery.All = true
				// }
				this.API[method](this.ListQuery).then(res => {
					this.loading = false
					this.GetTaskList = res.data.rows
					this.total = res.data.total
				})
			},
			taskdetail(ids, index) {
				this.$Dialog.taskDetails({
					taskId: ids
				}).then(res => {
					this.GetTaskList.splice(index, 1)
					this.$forceUpdate()
				})
			},
		}
	};
</script>

<style scoped>
	.icon {
		width: 23px;
		height: 23px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}

	.BgIcon {
		width: 100px;
		height: 100px;
		opacity: 0.3;
	}

	.task-div {
		cursor: pointer;
	}

	.task-div:hover {
		background-color: #efefef;
	}
</style>
